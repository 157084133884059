import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import ContentWrap from '../ContentWrap/ContentWrap';
import * as styles from './PaymentStatus.module.scss';
import Button from '../Button/Button';
import { ButtonSize } from '../../const/button.conts';
import { CHAT_ROUTE, SUPPORT_ROUTE } from '../../const/routes.const';

type PaymentStatusProps = {
  title: string | React.ReactNode;
  text?: string | React.ReactNode;
};
const PaymentStatus = ({
  title,
  text,
}: PaymentStatusProps): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      <ContentWrap>
        <div className={styles.title}>{title}</div>
        {text && <div className={styles.text}>{text}</div>}
        <div className={styles.buttons}>
          <Button
            label={<Trans>Support</Trans>}
            size={ButtonSize.middle}
            link={SUPPORT_ROUTE}
            externalLink
          />
          <Button
            label={<Trans>Chat</Trans>}
            size={ButtonSize.middle}
            link={CHAT_ROUTE}
            externalLink
          />
        </div>
      </ContentWrap>
    </div>
  );
};

export default PaymentStatus;
