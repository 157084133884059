// extracted by mini-css-extract-plugin
export var antBtnLg = "PaymentStatus-module--ant-btn-lg--kvT9G";
export var antBtnPrimary = "PaymentStatus-module--ant-btn-primary--8NP-d";
export var antFormItem = "PaymentStatus-module--ant-form-item--MCw2L";
export var antFormItemLabel = "PaymentStatus-module--ant-form-item-label--AvYvv";
export var antInput = "PaymentStatus-module--ant-input--YocJ9";
export var antMessageNotice = "PaymentStatus-module--ant-message-notice--SYRCI";
export var antMessageNoticeContent = "PaymentStatus-module--ant-message-notice-content--5kwLZ";
export var buttons = "PaymentStatus-module--buttons--qhQso";
export var errorMessage = "PaymentStatus-module--errorMessage--A1G8k";
export var text = "PaymentStatus-module--text--3eF5r";
export var title = "PaymentStatus-module--title--xVcI7";
export var wrapper = "PaymentStatus-module--wrapper--HABE4";